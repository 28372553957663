html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
	height: 100%;
}

body {
	min-width: 20rem;
}

html {
	touch-action: manipulation;
}

html[data-modal-active="true"] {
	touch-action: none;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: auto;
	overflow: hidden;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html.hide-mouse * {
	cursor: none !important;
}

body,
#__next {
	display: flex;
	flex-direction: column;
}

#__next {
	flex: 1 1 auto;
}

*:focus {
	outline: none;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
	appearance: none;
	-webkit-appearance: none !important;
	background-color: transparent;
	border: none;
	padding: 0 0 0 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

a:focus-visible {
	outline: 2px dotted white;
	outline-offset: 2px;
	border-radius: 3px;
}

input[type="search"] {
	-webkit-appearance: none !important;
	outline-offset: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/* https://www.cssportal.com/style-input-range/ */

	input[type="range"].input-range {
		height: 40px;
		-webkit-appearance: none;
		margin: 0;
		width: 100%;
	}
	input[type="range"].input-range:focus {
		outline: none;
	}
	input[type="range"].input-range::-webkit-slider-runnable-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 23px;
		background: #4ca471;
		border-radius: 11.5px;
		border: 0;
	}
	input[type="range"].input-range::-webkit-slider-thumb {
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -5.5px;
		border: 0;
		height: 34px;
		width: 10px;
		border-radius: 17px;
		background: #fefae8;
	}
	input[type="range"].input-range:focus::-webkit-slider-runnable-track {
		background: #4ca471;
	}
	input[type="range"].input-range::-moz-range-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 23px;
		background: #4ca471;
		border-radius: 11.5px;
		border: 0;
	}
	input[type="range"].input-range::-moz-range-thumb {
		cursor: pointer;
		border: 0;
		height: 34px;
		width: 10px;
		border-radius: 17px;
		background: #fefae8;
	}
	input[type="range"].input-range-thin {
		height: 40px;
		-webkit-appearance: none;
		margin: 0;
		width: 100%;
	}
	input[type="range"].input-range-thin:focus {
		outline: none;
	}
	input[type="range"].input-range-thin::-webkit-slider-runnable-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 6px;
		background: #4ca471;
		border-radius: 3px;
		border: 0;
	}
	input[type="range"].input-range-thin::-webkit-slider-thumb {
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -14px;
		border: 0;
		height: 34px;
		width: 10px;
		border-radius: 17px;
		background: #fefae8;
	}
	input[type="range"].input-range-thin:focus::-webkit-slider-runnable-track {
		background: #4ca471;
	}
	input[type="range"].input-range-thin::-moz-range-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 6px;
		background: #4ca471;
		border-radius: 3px;
		border: 0;
	}
	input[type="range"].input-range-thin::-moz-range-thumb {
		cursor: pointer;
		border: 0;
		height: 34px;
		width: 10px;
		border-radius: 17px;
		background: #fefae8;
	}
	input[type="range"].settings-slider {
		height: 28px;
		-webkit-appearance: none;
		margin: 0;
		width: 100%;
	}
	input[type="range"].settings-slider:focus {
		outline: none;
	}
	input[type="range"].settings-slider::-webkit-slider-runnable-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 4px;
		background: #425b5b;
		border-radius: 2px;
		border: 0;
	}
	input[type="range"].settings-slider::-webkit-slider-thumb {
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -9px;
		border: 0;
		height: 22px;
		width: 10px;
		border-radius: 11px;
		background: #fffae6;
	}
	input[type="range"].settings-slider:focus::-webkit-slider-runnable-track {
		background: #425b5b;
	}
	input[type="range"].settings-slider::-moz-range-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 4px;
		background: #425b5b;
		border-radius: 2px;
		border: 0;
	}
	input[type="range"].settings-slider::-moz-range-thumb {
		cursor: pointer;
		border: 0;
		height: 22px;
		width: 10px;
		border-radius: 11px;
		background: #fffae6;
	}
	input[type="range"].red-slider {
		height: 39px;
		-webkit-appearance: none;
		margin: 0;
		width: 100%;
	}
	input[type="range"].red-slider:focus {
		outline: none;
	}
	input[type="range"].red-slider::-webkit-slider-runnable-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 10px;
		background: #ff94ab;
		border-radius: 5px;
		border: 0;
	}
	input[type="range"].red-slider::-webkit-slider-thumb {
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -11.5px;
		border: 0;
		height: 33px;
		width: 14px;
		border-radius: 16.5px;
		background: #fefae8;
	}
	input[type="range"].red-slider:focus::-webkit-slider-runnable-track {
		background: #ff94ab;
	}
	input[type="range"].red-slider::-moz-range-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 10px;
		background: #ff94ab;
		border-radius: 5px;
		border: 0;
	}
	input[type="range"].red-slider::-moz-range-thumb {
		cursor: pointer;
		border: 0;
		height: 33px;
		width: 14px;
		border-radius: 16.5px;
		background: #fefae8;
	}
	input[type="range"].black-slider {
		height: 24px;
		-webkit-appearance: none;
		margin: 0;
		width: 100%;
	}
	input[type="range"].black-slider:focus {
		outline: none;
	}
	input[type="range"].black-slider::-webkit-slider-runnable-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 6px;
		background: #000000;
		border-radius: 3px;
		border: 0;
	}
	input[type="range"].black-slider::-webkit-slider-thumb {
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -6px;
		border: 0;
		height: 18px;
		width: 18px;
		border-radius: 9px;
		background: #000000;
	}
	input[type="range"].black-slider:focus::-webkit-slider-runnable-track {
		background: #000000;
	}
	input[type="range"].black-slider::-moz-range-track {
		width: 100%;
		cursor: pointer;
		animate: 0.2s;
		height: 6px;
		background: #000000;
		border-radius: 3px;
		border: 0;
	}
	input[type="range"].black-slider::-moz-range-thumb {
		cursor: pointer;
		border: 0;
		height: 18px;
		width: 18px;
		border-radius: 9px;
		background: #000000;
	}

}

@media (min-width: 1024px) {
	.home-bg {
		background-image: url("/images/backgrounds/bg-pattern-start.svg");
		background-color: transparent;
	}
}
.navigation-bg {
	background-image: url("/images/backgrounds/background-navigation.svg");
	background-size: 1024px 56px;
	background-position: center;
	background-repeat: repeat;
}

.welcome-bg {
	background-image: url("/images/backgrounds/background-welcome.svg");
	background-size: cover;
	background-position: center 20%;
	background-repeat: repeat-y;
}

.range-bg
{
	background-image: 
    repeating-linear-gradient(
      90deg,
      transparent 6px,
      transparent 12px,
      #eae8e180 12px,
      #eae8e180 16px
    );
}

@keyframes animate-avatar-outline {
	50%  { stroke-opacity: 0.8; }
}

svg g[data-outline="true"] path {
	stroke-opacity: 0.1;
	animation: animate-avatar-outline 1s ease-in-out infinite;
}


@layer utilities {
	.touch-auto {
		touch-action: auto;
	}
	.touch-none {
		touch-action: none;
	}
	.touch-pan-x {
		touch-action: pan-x;
	}
	.touch-pan-left {
		touch-action: pan-left;
	}
	.touch-pan-right {
		touch-action: pan-right;
	}
	.touch-pan-y {
		touch-action: pan-y;
	}
	.touch-pan-up {
		touch-action: pan-up;
	}
	.touch-pan-down {
		touch-action: pan-down;
	}
	.touch-pinch-zoom {
		touch-action: pinch-zoom;
	}
	.touch-manipulation {
		touch-action: manipulation;
	}
	.pause-anim {
		animation-play-state: paused;
	}
}

@layer components {
	.custom-container {
		position: relative;
		width: 100%;
	}
	@media (min-width: 640px) {
		.custom-container {
			max-width: 608px;
		}
	}
	@media (min-width: 768px) {
		.custom-container {
			max-width: 736px;
		}
	}
	@media (min-width: 1024px) {
		.custom-container {
			max-width: 992px;
		}
	}
	@media (min-width: 1280px) {
		.custom-container {
			max-width: 1248px;
		}
	}
	@media (min-width: 1536px) {
		.custom-container {
			max-width: 1504px;
		}
	}

	.animate-dotty::after {
		display: inline-block;
		animation: animate-dotty steps(1,end) 1s infinite;
		content: "";
	}

	@keyframes animate-dotty {
		0%   { content: ''; }
		25%  { content: '.'; }
		50%  { content: '..'; }
		75%  { content: '...'; }
		100% { content: ''; }
	}

	@keyframes live {
		50% {
			opacity: 0.5;
		}
	}
	.animate-live{
		animation: live 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
	}

	@keyframes infobar {
		50% {
			background-color: #d5456c;
		}
	}
	.animate-infobar {
		animation: infobar 2s ease-in-out infinite;
	}
}

/*
https://github.com/JustFly1984/react-google-maps-api/issues/2906
https://stackoverflow.com/questions/69027683/react-google-maps-api-googlemap-onclick-not-working
*/
.gm-style-moc {
	pointer-events: none;
}
